import React from 'react';

import Layout from 'components/Layout';
import Container from 'components/Container';
import octoberCover from "../../images/lawn/october2018.png";
import july2020Cover from "../../images/lawn/july2019.png";
import soi2019Cover from "../../images/lawn/soi2019.png";
import october2020 from "../../images/lawn/october2020.png";
import october2020Cover from "../../images/lawn/october2020-1.png";
import may2020Cover from "../../images/lawn/may2020.png";
import march2017 from "../../images/lawn/march2017.png";
import julyWater from "../../images/lawn/july2017.png";
import december2020 from "../../images/lawn/december2020.png";
import womenApril from "../../images/lawn/april2021.png";
import blueDiamonds from '../../images/blue-diamonds.svg';
import lawnImg from '../../images/lawn/march2021-cover.png';
import {Link} from "gatsby";
import Seo from "../../components/Seo";

import soi1 from "../../images/lawn/October2021SOI-1.png";
import soi2 from "../../images/lawn/October2021SOI-2.png";
import soi3 from "../../images/lawn/October2021SOI-3.png";

import benchmark1 from "../../images/lawn/benchmark1.png";
import benchmark2 from "../../images/lawn/benchmark2.png";
import benchmark4 from "../../images/lawn/benchmark4.png";

const LawnPage = () => {
    return (
            <Layout pageName="lawn">
                <Seo
                        title={'Lawn & Landscape'}
                />
                <Container>
                    <div className="grid grid-cols-1 gap-10">
                        <div className="grid md:grid-cols-6 md:gap-10 content-center md:mb-16">
                            <img src={october2020} alt="Lawn & Landscape" className="md:col-span-3 w-full"/>
                            <div className="text-left md:col-span-3 lg:col-span-2 mt-4 md:mt-0">
                                <h2 className="text-7xl font-serif">Lawn &<br/>Landscape</h2>
                                <h3 className="font-mono text-xl mt-4">May 2014 — Present</h3>
                                <img src={blueDiamonds} alt="" className="mt-6"/>
                                <p className="mt-8">As art director of Lawn & Landscape, the largest B2B publication serving the landscaping industry, I worked with my editorial and sales team to create nationally recognized content for our print publication of 70,000+ subscribers.</p>
                                <p className="mt-4">I also worked with a large network of extremely talented freelance photographers and illustrators to create unique, interesting content for our audience.</p>
                            </div>
                        </div>
                        <img src={soi1} alt="" className="max-w-full"/>
                        <img src={soi2} alt="" className="max-w-full"/>
                        <img src={soi3} alt="" className="max-w-full"/>
                        <div className="grid md:grid-cols-2 gap-10">
                            <img src={octoberCover} alt="Lawn & Landscape" className="max-w-full"/>
                            <img src={lawnImg} alt="Lawn & Landscape" className="max-w-full"/>
                        </div>
                        <img src={benchmark1} alt="" className="max-w-full"/>
                        <img src={benchmark2} alt="" className="max-w-full"/>
                        <img src={benchmark4} alt="" className="max-w-full"/>
                        <div className="grid md:grid-cols-2 gap-10">
                            <img src={may2020Cover} alt="Lawn & Landscape" className="max-w-full"/>
                            <img src={july2020Cover} alt="Lawn & Landscape" className="max-w-full"/>
                        </div>
                        <img src={soi2019Cover} alt="Lawn & Landscape" className="max-w-full"/>
                        <img src={julyWater} alt="Lawn & Landscape" className="max-w-full"/>
                        <div className="grid md:grid-cols-2 gap-10">
                            <img src={december2020} alt="Lawn & Landscape" className="max-w-full"/>
                            <img src={womenApril} alt="Lawn & Landscape" className="max-w-full"/>
                        </div>
                        <img src={october2020Cover} alt="Lawn & Landscape" className="max-w-full"/>
                        <img src={march2017} alt="Lawn & Landscape" className="max-w-full"/>
                        <div className="md:mt-16">
                            <Link to="/work/birdeye" className="text-4xl font-serif hover:text-pink transition-colors duration-200">Next Project: BirdEye Ohio</Link>
                        </div>
                    </div>
                </Container>
            </Layout>
    );
};

export default LawnPage;
